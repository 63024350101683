import { FC } from 'react';
import { OilBarIcon, OilBarWhiteIcon } from '../../icons';

interface OilBarProps {
  gasCost: number;
  white?: boolean;
}
export const OilBar: FC<OilBarProps> = ({ gasCost, white = false }) => {
  return (
    <div className="flex flex-col gap-m">
      <div className="relative flex h-[54px] w-full">
        <div className="absolute left-0 top-0 z-10 flex h-[65px]">
          {white ? <OilBarWhiteIcon /> : <OilBarIcon />}
        </div>
        <div className="relative z-0 ml-[21px] flex w-full min-w-max rounded-r-small bg-oil-bar py-l pl-[39px] pr-xl text-neutral-900">
          <span className="text-body2Medium text-neutral-0">
            ${gasCost.toLocaleString()}
            <span className="text-body2Light text-neutral-0">/ year</span>
          </span>
        </div>
      </div>
      <div>
        <span className="text-microMedium text-neutral-900">Gasoline </span>
        <span className="text-microLight text-neutral-900">cost</span>
      </div>
    </div>
  );
};
